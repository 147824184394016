import Vue from 'vue'
import VueRouter from 'vue-router'
import BasicLayout from '../views/basicLayout/index'
import checkRoot from '../views/checkRoot/index.vue'

Vue.use(VueRouter)

// 在vue项目中，如果使用$router.push跳转到一个相同的路由报错 解决方法
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'BasicLayout',
    redirect: '/taskHome',
    component: BasicLayout,
    children: [
      {
        path: '/checkRoot',
        name: 'checkRoot',
        meta: {
          title: '登录'
        },
        component: checkRoot
      },
      {
        path: '/taskHome',
        name: 'TaskHome',
        meta: {
          title: '任务大厅'
        },
        component: () => import('../views/taskHome/index.vue')
      }, {
        path: '/taskHome/taskInfo',
        name: 'TaskInfo',
        meta: {
          title: '任务详情'
        },
        component: () => import('../views/taskInfo/index.vue')
      }, {
        path: '/mine/myPro',
        name: 'myPro',
        meta: {
          title: '我的项目'
        },
        component: () => import('../views/myPro/index.vue')
      }, {
        path: '/mine/uploadDeliverables',
        name: 'uploadDeliverables',
        meta: {
          title: '上传交付物'
        },
        component: () => import('../views/uploadDeliverables/index.vue')
      }, {
        path: '/mine/settlement',
        name: 'settlement',
        meta: {
          title: '结算记录'
        },
        component: () => import('../views/settlement/index.vue')
      }, {
        path: '/mine/settlementInfo',
        name: 'settlementInfo',
        meta: {
          title: '结算详情'
        },
        component: () => import('../views/settlementInfo/index.vue')
      }, {
        path: '/mine/userInfo',
        name: 'userInfo',
        meta: {
          title: '我的信息'
        },
        component: () => import('../views/userInfo/index.vue')
      },
      {
        path: '/mine/about',
        name: 'about',
        meta: {
          title: '关于我们'
        },
        component: () => import('../views/index/index.vue')
      },
      {
        path: '/generatePerson',
        name: 'generatePerson',
        meta: {
          title: '人脸识别'
        },
        component: () => import('../views/face/index.vue')
      },
      {
        path: '/mine/server',
        name: 'server',
        meta: {
          title: '服务协议'
        },
        component: () => import('../views/server/index.vue')
      },
      {
        path: '/mine/declare',
        name: 'declare',
        meta: {
          title: '申报明细'
        },
        component: () => import('../views/declare/index.vue')
      },
      {
        path: '/mine/declareDetail',
        name: 'declareDetail',
        meta: {
          title: '申报明细详情'
        },
        component: () => import('../views/declare/detail.vue')
      }
    ]
  }, {
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_API,
  routes
})

const getRoot = function (url) {
  const appid = process.env.VUE_APP_BASE_APPID
  const redirectURI = encodeURIComponent(`${process.env.VUE_APP_BASE_REDIRECT}/checkRoot?url=${url}`)
  const link = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirectURI}&response_type=code&scope=snsapi_base#wechat_redirect`
  location.href = link
}
const getQueryObject = function (url) {
  url = url == null ? window.location.href : url
  const search = url.substring(url.lastIndexOf('?') + 1)
  const obj = {}
  const reg = /([^?&=]+)=([^?&=]*)/g
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1)
    let val = decodeURIComponent($2)
    val = String(val)
    obj[name] = val
    return rs
  })
  return obj
}

const whiteList = ['/checkRoot'] // 白名单

router.beforeEach((to, from, next) => {
  // 路由发生变化修改页面title
  if (to.meta.title) {
    document.title = to.meta.title
  }

  // 解决IOS客户端在history模式下签名失败问题
  if (window.entryUrl === undefined) {
    window.entryUrl = document.URL
  }

  // 与keepLive缓存组件冲突暂弃用
  // const u = navigator.userAgent
  // const IsiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) // ios终端
  // if (IsiOS && to.path !== location.pathname) {
  //   location.assign(to.fullPath)
  // }

  // 跳过授权--本地测试时启用
  // localStorage.setItem('MERNO', 'MI68134969546338304')
  // sessionStorage.setItem('TOKEN', '57ad3248-4617-4996-8517-aa41417c0a58')
  // sessionStorage.setItem('TOKEN', 'bed10ebc-9fd5-4eae-9529-b5e4837ca64e')
  // next()

  // 授权相关处理
  if (whiteList.indexOf(to.path) !== -1) {
    next()
  } else {
    if (to.path === '/mine/userInfo') {
      const urlData = getQueryObject(to.fullPath)
      if (urlData.content) {
        localStorage.setItem('MERNO', urlData.content)
      } else {
        // localStorage.setItem('MERNO', 'MI117371407499763712')
      }
    }
    const token = sessionStorage.getItem('TOKEN')
    if (token) {
      next()
    } else {
      getRoot(to.fullPath)
    }
  }
})

export default router
