<template>
  <div>{{ msg }}</div>
</template>
<script>
import requestHTAPI from '@/axios/HantangTax'
import { getUrlParam } from '@/libs/utils'
export default {
  name: 'checkRoot',
  data () {
    return {
      code: '',
      url: '',
      msg: '请求中...'
    }
  },
  mounted () {
    this.code = getUrlParam('code')
    this.url = decodeURIComponent(getUrlParam('url'))
    this.sendCode(this.url)
  },
  methods: {
    sendCode (url) {
      // 发请求拿openid
      const formData = new FormData()
      formData.append('code', this.code)
      requestHTAPI.sendCode(formData).then(res => {
        if (res.data.code == '0000') {
          // code 给后台并存上token
          const data = res.data.data
          sessionStorage.setItem('TOKEN', data.token)
          this.$router.push({
            path: url
          })
        } else {
          // this.sendCode(url)
          this.msg = '请重新授权'
          this.$toast.fail('请重新授权')
        }
      }).catch(error => {
        console.log(error)
        // this.sendCode(url)
        this.msg = '服务异常，请重新授权'
        this.$toast.fail('服务异常，请重新授权')
      })
    }
  }
}
</script>
<style scoped>
</style>
