import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import {
  Col, Row, Button, Tabbar, TabbarItem, ActionSheet, Toast, Notify, Search, List, Empty, Image as VanImage, Icon, Loading, CellGroup, Field, Cell, Stepper, Form,
  Uploader, Calendar, Popup, Picker, CheckboxGroup, checkbox, CountDown, PullRefresh, RadioGroup, Radio, Dialog, DropdownMenu, DropdownItem, Sticky, TreeSelect
} from 'vant'

Vue.use(Col).use(Row).use(Button).use(Tabbar).use(TabbarItem).use(ActionSheet).use(Search).use(List).use(Empty).use(VanImage).use(Icon).use(Loading).use(CellGroup).use(Cell).use(Stepper).use(Form).use(Field).use(Uploader).use(Calendar).use(Popup).use(Picker).use(PullRefresh).use(checkbox).use(CheckboxGroup).use(CountDown).use(RadioGroup).use(Radio).use(Dialog).use(DropdownMenu).use(DropdownItem).use(Sticky).use(TreeSelect)

Vue.config.productionTip = false
Vue.prototype.$toast = Toast
Vue.prototype.$notify = Notify
Vue.prototype.$dialog = Dialog

// 公众号跳转小程序 组件未注册报错处理
Vue.config.ignoredElements = ['wx-open-launch-weapp']

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
