// 判断图片路径是否有效
export const checkImgExists = (imgurl) => {
  return new Promise((resolve, reject) => {
    const ImgObj = new Image()
    ImgObj.src = imgurl
    ImgObj.onload = res => {
      resolve(res)
    }
    ImgObj.onerror = err => {
      reject(err)
    }
  })
}

// 获取地址栏指定参数的值
export const getUrlParam = (paramName) => {
  // 获取url中跟在问号后面的部分
  const params = window.location.search
  // 检测参数是否存在
  if (params.indexOf(paramName) > -1) {
    let paramValue = ''
    paramValue = params.substring(params.indexOf(paramName), params.length)
    // 检测后面是否还有参数
    if (paramValue.indexOf('&') > -1) {
      // 去除后面多余的参数, 得到最终 paramName=paramValue 形式的值
      paramValue = paramValue.substring(0, paramValue.indexOf('&'))
      // 去掉参数名, 得到最终纯值字符串
      paramValue = paramValue.replace(paramName + '=', '')
      return paramValue
    } else {
      paramValue = paramValue.replace(paramName + '=', '')
      return paramValue
    }
  }
}

// 使用canvas压缩图片
export const compress = (img, Orientation) => {
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  const tCanvas = document.createElement('canvas')
  const tctx = tCanvas.getContext('2d')
  let width = img.width
  let height = img.height
  // 如果图片大于四百万像素，计算压缩比并将大小压至400万以下
  let ratio
  if ((ratio = (width * height) / 4000000) > 1) {
    // console.log("大于400万像素");
    ratio = Math.sqrt(ratio)
    width /= ratio
    height /= ratio
  } else {
    ratio = 1
  }
  canvas.width = width
  canvas.height = height
  //    铺底色
  ctx.fillStyle = '#fff'
  ctx.fillRect(0, 0, canvas.width, canvas.height)
  // 如果图片像素大于100万则使用瓦片绘制
  let count
  if ((count = (width * height) / 1000000) > 1) {
    // console.log("超过100W像素");
    count = ~~(Math.sqrt(count) + 1) // 计算要分成多少块瓦片
    //      计算每块瓦片的宽和高
    const nw = ~~(width / count)
    const nh = ~~(height / count)
    tCanvas.width = nw
    tCanvas.height = nh
    for (let i = 0; i < count; i++) {
      for (let j = 0; j < count; j++) {
        tctx.drawImage(img, i * nw * ratio, j * nh * ratio, nw * ratio, nh * ratio, 0, 0, nw, nh)
        ctx.drawImage(tCanvas, i * nw, j * nh, nw, nh)
      }
    }
  } else {
    ctx.drawImage(img, 0, 0, width, height)
  }
  // 修复ios上传图片的时候 被旋转的问题
  if (Orientation != '' && Orientation != 1) {
    switch (Orientation) {
      case 6: // 需要顺时针（向左）90度旋转
        rotateImg(img, 'left', canvas)
        break
      case 8: // 需要逆时针（向右）90度旋转
        rotateImg(img, 'right', canvas)
        break
      case 3: // 需要180度旋转
        rotateImg(img, 'right', canvas) // 转两次
        rotateImg(img, 'right', canvas)
        break
    }
  }
  // 进行最小压缩
  const ndata = canvas.toDataURL('image/jpeg', 0.1)
  tCanvas.width = tCanvas.height = canvas.width = canvas.height = 0
  return ndata
}

// 旋转图片
export const rotateImg = (img, direction, canvas) => {
  // 最小与最大旋转方向，图片旋转4次后回到原方向
  const min_step = 0
  const max_step = 3
  if (img == null) return
  // img的高度和宽度不能在img元素隐藏后获取，否则会出错
  const height = img.height
  const width = img.width
  let step = 2
  if (step == null) {
    step = min_step
  }
  if (direction == 'right') {
    step++
    // 旋转到原位置，即超过最大值
    step > max_step && (step = min_step)
  } else {
    step--
    step < min_step && (step = max_step)
  }
  // 旋转角度以弧度值为参数
  const degree = (step * 90 * Math.PI) / 180
  const ctx = canvas.getContext('2d')
  switch (step) {
    case 0:
      canvas.width = width
      canvas.height = height
      ctx.drawImage(img, 0, 0)
      break
    case 1:
      canvas.width = height
      canvas.height = width
      ctx.rotate(degree)
      ctx.drawImage(img, 0, -height)
      break
    case 2:
      canvas.width = width
      canvas.height = height
      ctx.rotate(degree)
      ctx.drawImage(img, -width, -height)
      break
    case 3:
      canvas.width = height
      canvas.height = width
      ctx.rotate(degree)
      ctx.drawImage(img, -width, 0)
      break
  }
}

// 将base64转换为文件
export const dataURLtoFile = (dataurl, files) => {
  const arr = dataurl.split(',')
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], files.name, {
    type: files.type
  })
}

// 原理：在规定的时间内没有再次触发，被判定为有效
// debounce(函数名, 防抖时间, true)  防抖
let timeout = null
export const debounce = (func, wait = 500, immediate = false) => {
  // 清除定时器
  if (timeout !== null) clearTimeout(timeout)
  // 立即执行，此类情况一般用不到
  if (immediate) {
    var callNow = !timeout
    timeout = setTimeout(function () {
      timeout = null
    }, wait)
    if (callNow) typeof func === 'function' && func()
  } else {
    // 设置定时器，当最后一次操作后，timeout不会再被清除，所以在延时wait毫秒后执行func回调方法
    timeout = setTimeout(function () {
      typeof func === 'function' && func()
    }, wait)
  }
}

// 原理：规定的时间内只能触发一次 节流
export const throttle = (func, wait) => {
  let context, args
  let previous = 0
  return () => {
    // 当前时间戳
    const now = +new Date() // 相当于 let now = new Date().getTime()
    context = this
    args = arguments
    // 当前的时间戳 - 之前的时间戳
    if (now - previous > wait) {
      func.apply(context, args)
      previous = now
    }
  }
}
