<template>
  <div id="basic-layout">
    <keep-alive :include="['userInfo', 'declare']">
      <router-view/>
    </keep-alive>
  </div>
</template>
<script>
export default {
  name: 'BasicLayout',
  data () {
    return {

    }
  }

}
</script>
<style scoped>
  #basic-layout{
    height: 100%;
  }
</style>
